import './FillerAni.css'
import React from 'react'

export default function FillerAni() {

    return (
        <div className="filler-ani-wrapper">
            <div className='inner-ani-wrapper'>
                <div className='inner-ani-wrapper2'>
                    <svg id="filler-ani" data-name="filler-ani" className='logo-group' xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 -100 800 1000">
                        <defs>
                        </defs>
                        <path className="cls-1" d="M347.5,14.61L95.52,160.09c-33.74,19.48-54.52,55.48-54.52,94.43v290.95c0,38.96,20.78,74.96,54.52,94.44l251.97,145.48c33.74,19.48,75.31,19.48,109.04,0l251.97-145.48c33.74-19.48,54.52-55.48,54.52-94.44V254.52c0-38.96-20.78-74.96-54.52-94.43L456.54,14.61c-33.74-19.48-75.31-19.48-109.05,0Zm83.98,692.12l-25.81-74.36c-1.2-3.45-6.09-3.45-7.28,0l-25.81,74.36c-2.25,6.5-9.87,9.33-15.82,5.89l-200.76-115.91c-29.3-16.91-47.34-48.17-47.34-82v-231.82c0-6.88,6.26-12.05,13.01-10.76l77.3,14.82c3.59,.69,6.03-3.54,3.64-6.31l-51.49-59.53c-4.5-5.2-3.15-13.21,2.81-16.65l200.76-115.91c29.3-16.91,65.39-16.91,94.68,0l200.76,115.91c5.95,3.44,7.31,11.45,2.81,16.65l-51.49,59.53c-2.39,2.77,.05,7,3.64,6.31l77.3-14.82c6.75-1.29,13.01,3.88,13.01,10.76v231.82c0,33.83-18.05,65.09-47.34,82l-200.76,115.91c-5.95,3.44-13.57,.6-15.82-5.89Z" />
                        <path className="cls-1" d="M308.4,350.15l59.8,69.37,30.18,86.47c1.2,3.45,6.08,3.45,7.28,0l30.18-86.47,59.8-69.37c2.38-2.77-.05-6.99-3.64-6.3l-89.98,17.1-89.98-17.1c-3.59-.68-6.02,3.54-3.64,6.3Z" />
                    </svg>
                </div>
            </div>
        </div>

    )

}