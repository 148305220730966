import React from 'react'
import './ProjectCard.css';
import useFetch from '../hooks/useFetch'
import { Link} from 'react-router-dom';
import Loader from './Loader';
import LinesEllipsis from 'react-lines-ellipsis';

const ProjectCard = () => {
    const { loading, error, data } = useFetch('https://hypersquare-backend.herokuapp.com/api/projects?populate=previewmedia&sort=date')

    if (loading) return <Loader />
    if (error) return <p>There has been an Error.</p>
    return (

        <div className='project-wrapper'>
            {data.data.map((article, i) => (
                <Link key={`project-card-link${i}`} to={`/projects/${article.attributes.slug}`} className='project-card-link' >
                    <div key={`project-card-wrapper${i}`} className='project-card-wrapper'>
                        <div key={`project-card${i}`} className='project-card'>
                            <img key={`project-card-image${i}`} src={article.attributes.previewmedia.data.attributes.url}
                                className='project-card-image'></img>
                        </div>
                        <div key={`project-card-text${i}`} className='project-card-text'>
                            <h2 key={`project-card-title${i}`}>{article.attributes.title}</h2>
                            <h3 key={`project-card-subtitle${i}`}>{article.attributes.subtitle}</h3>                            
                            <LinesEllipsis text={article.attributes.introtext} maxLine='3' ellipsis='...' basedOn='letters' />
                        
                        </div>
                        <div key={`project-text-link${i}`} className='text-link'>See full project<span className="bi bi-arrow-right-short"></span></div>
                    </div>
                </Link>))
            }
        </div >
    )
}

export default ProjectCard;