import React from "react";
import './ServiceSection.css'
import useFetch from '../hooks/useFetch'
import ReactMarkdown from 'react-markdown'
import { ServiceCard } from "./ServiceCard";
import Loader from "./Loader";


export const ServiceSection = () => {

    const { loading, error, data } = useFetch('https://hypersquare-backend.herokuapp.com/api/service-head?populate=media')
    if (loading) return <Loader />
    if (error) return <p>There has been an Error.</p>
    return (
        <section className='service-section ' id='services'>
            <div className="main-container">
                <div className='service-head '>
                    <video autoPlay muted loop playsInline >
                        <source src={data.data.attributes.media.data.attributes.url} type={data.data.attributes.media.data.attributes.mime} />
                        <img src={data.data.attributes.media.data.attributes.previewUrl} />
                    </video>
                    <div className='text-wrapper'>
                        <span className='intro-subtitle'>{data.data.attributes.subtitle}</span>
                        <h1>{data.data.attributes.title}</h1>
                        <ReactMarkdown>{data.data.attributes.description}</ReactMarkdown>
                    </div>
                </div>
            </div>
            <ServiceCard />
        </section>
    )
}