import React from 'react'
import './Loader.css';

export default function Loader() {
    return (
        <div className='loader-wrapper'>
            <svg id="loading-logo" data-name="loading-logo" xmlns="http://www.w3.org/2000/svg" width="500" height="500" viewBox="0 0 500 500">
                <defs>
                </defs>
                <g>
                    <path d="M215.92,9.13,58.44,100.06a68.14,68.14,0,0,0-34.08,59V340.92a68.17,68.17,0,0,0,34.08,59l157.48,90.92a68.19,68.19,0,0,0,68.16,0L441.56,400a68.17,68.17,0,0,0,34.08-59V159.08a68.14,68.14,0,0,0-34.08-59L284.08,9.13A68.19,68.19,0,0,0,215.92,9.13Zm52.49,432.58-16.13-46.48a2.42,2.42,0,0,0-4.56,0l-16.13,46.48a6.84,6.84,0,0,1-9.89,3.68L96.23,373A59.18,59.18,0,0,1,66.64,321.7V176.81a6.84,6.84,0,0,1,8.13-6.72l48.31,9.26a2.41,2.41,0,0,0,2.28-3.94L93.18,138.2a6.84,6.84,0,0,1,1.75-10.4L220.41,55.35a59.19,59.19,0,0,1,59.18,0L405.06,127.8a6.84,6.84,0,0,1,1.76,10.4l-32.18,37.21a2.41,2.41,0,0,0,2.27,3.94l48.32-9.26a6.84,6.84,0,0,1,8.13,6.72V321.7A59.18,59.18,0,0,1,403.77,373L278.3,445.39A6.84,6.84,0,0,1,268.41,441.71Z" />
                    <path d="M191.49,218.85l37.37,43.35,18.87,54.05a2.41,2.41,0,0,0,4.55,0l18.86-54.05,37.37-43.35a2.41,2.41,0,0,0-2.27-3.94L250,225.59,193.76,214.9A2.41,2.41,0,0,0,191.49,218.85Z" />
                </g>
            </svg>
        </div>
    )
}
