import React from 'react'
import './Navbar.css';
import { Link } from 'react-scroll';

export default function Navbar() {
    return (
        <>
            <nav className="navbar">
                <Link className="nav-link" id="first-nav"
                    activeClass='active'
                    to="services"
                    spy={true}
                    smooth={true}
                    duration={800}
                    ignoreCancelEvents={true}>
                    <span className="bi bi-compass"></span><span className='nav-text'>Services</span></Link>
                <Link className="nav-link"
                    activeClass='active'
                    to="projects"
                    spy={true}
                    smooth={true}
                    duration={800}
                    ignoreCancelEvents={true}>
                    <span className="bi bi-circle-half"></span><span className='nav-text'>Projects</span></Link>
                <Link className="nav-link"
                    activeClass='active'
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={800}
                    ignoreCancelEvents={true}>
                    <span className="bi bi-chat-dots"></span><span className='nav-text'>Contact</span></Link>
            </nav>
        </>
    );
}