import React from 'react'
import '../App.css';
import useFetch from '../hooks/useFetch'
import { Outlet} from 'react-router-dom';

import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import { ServiceSection } from '../components/ServiceSection';
import ProjectSection from '../components/ProjectSection';
import FooterSection from '../components/FooterSection';
import Loader from '../components/Loader';
import FillerAni from '../components/FillerAni';
import { Helmet } from 'react-helmet';

export const Homepage = () => {

    const { loading, error, data } = useFetch('https://hypersquare-backend.herokuapp.com/api/sharing-info?populate=image')

    if (loading) return <Loader />
    if (error) return <p>There has been an Error.</p>

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hypersquare Design: Experience Design on the Digital Threshold</title>
                <meta property="og:title" content={data.data.attributes.title} />
                <meta property="og:description" content={data.data.attributes.description}  />
                <meta property="og:image" content={data.data.attributes.image.data.attributes.url} />
                <meta property="og:url" content="https://hypersquare.design" />
            </Helmet>

            <Outlet />
            <Navbar />
            <HeroSection />
            <ServiceSection />
            <ProjectSection />
            <section className='footer-section' id='contact'>
                <FillerAni />
                <FooterSection />
            </section>
        </>
    )


}

