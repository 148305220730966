import React, { useEffect } from 'react'
import { useParams} from 'react-router-dom'
import FooterSection from '../components/FooterSection';
import Loader from '../components/Loader'
import useFetch from '../hooks/useFetch'
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import './ServiceDetails.css';
import HypersquareLogo from '../components/HypersquareLogo';
import ScrollIntoView from 'react-scroll-into-view';
import { Helmet } from 'react-helmet';


export const ServiceDetails = () => {

    const { slug } = useParams()
    const { loading, error, data } = useFetch('https://hypersquare-backend.herokuapp.com/api/services/' + slug + '?populate=media')

    document.body.classList.add('scroll-blocker');
    useEffect(() => {
        return () => {
            document.body.classList.remove('scroll-blocker');
        }
    }, [])



    if (loading) return <Loader />
    if (error) return <p>There has been an Error.</p>


    return (

        <div className='service-detail-overlay' id='scroll-container'>

            <Helmet>
                <title>Hypersquare Design: {data.data.attributes.title}</title>
                <meta property="og:title" content={`Hypersquare Design: ${data.data.attributes.title}`} />
                <meta property="og:description" content={data.data.attributes.description} />
                <meta property="og:image" content={data.data.attributes.media.data.attributes.url} />
                <meta property="og:url" content={`https://hypersquare.design/projects/${data.data.attributes.slug}`}/>
                <meta property="og:type" content="article" />
            </Helmet>

            <div className='service-head-wrapper'>
                <div className='header-logo-wrapper'>
                    <Link to="/"><HypersquareLogo /></Link>
                    <Link to="/" className='text-link'><span className="bi bi-arrow-left-short back-icon"></span><span className='desktop-button'>Back to main page</span><span className='mobile-button'>Back</span></Link>
                </div>

                <div className='service-image-bg'>
                    <div className='image-pusher'></div>
                    <img className='service-head-img' src={data.data.attributes.media.data.attributes.url} />
                </div>
            </div>
            <div className='service-text-container'>
                <div className='service-text-wrapper'>

                    <h1 className='service-h1'>{data.data.attributes.title}</h1>
                    <div className='intro-text'><ReactMarkdown>{data.data.attributes.introtext}</ReactMarkdown>
                        <div className='intro-link-wrapper'>
                            <ScrollIntoView alignToTop={true} selector='#main-text-anchor' className='text-link'><span className="bi bi-arrow-down-short back-icon"></span><span>Continue reading</span></ScrollIntoView>

                            <ScrollIntoView alignToTop={true} selector='#gallery-anchor' className='text-link'><span className="bi bi-card-image back-icon"></span><span>View image</span></ScrollIntoView>
                        </div>
                        <div id='main-text-anchor'></div>
                    </div>
                    <div className='main-text'>
                        <ReactMarkdown>{data.data.attributes.text}</ReactMarkdown>
                        <div id='gallery-anchor'></div>
                    </div>



                </div>
                <div className='gallery-container'>
                    <div className='single-gallery-img-container'>
                        <img className='single-gallery-img' src={data.data.attributes.media.data.attributes.url} />
                    </div>
                </div>

                <div className='detail-footer'>
                    <FooterSection />
                </div>
            </div>
        </div>
    )
}
