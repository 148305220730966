import React, { useState, useEffect } from 'react'
import './HeroSection.css'
import useFetch from '../hooks/useFetch'
import ReactMarkdown from 'react-markdown'
import Loader from './Loader';

export default function HeroSection() {
    const { loading, error, data } = useFetch('https://hypersquare-backend.herokuapp.com/api/hero-area?populate=media')
    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(window.pageYOffset);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    //console.log(offsetY);

    function toggleMute() {

        var video = document.getElementById("hero-video-id");
        setIsActive(current => !current);

        video.muted = !video.muted;

    }

    if (loading) return <Loader />
    if (error) return <p>There has been an Error.</p>

    return (
        <section className='hero-container'>

            <div className={isActive ? 'video-overlay-bg deactivate-overlay' : 'video-overlay-bg'} ></div>
            <div className={isActive ? 'video-overlay deactivate-overlay' : 'video-overlay'} style={{ marginTop: offsetY * 0.5 + 'px' }}></div>
            
                <video autoPlay muted loop playsInline preload="metadata" className='hero-video' id='hero-video-id' style={{ objectPosition: '0 ' + offsetY * 0.6 + 'px' }}>
                    <source src={data.data.attributes.media.data.attributes.url} type={data.data.attributes.media.data.attributes.mime} />
                    <img src={data.data.attributes.media.data.attributes.url} />
                    Your browser does not support the video tag.
                </video>
            
            <div className='button-wrapper'>
                <button className={isActive ? 'mute-button cloak-button' : 'mute-button'} id='video-unmute-button' onClick={() => toggleMute()}><span className={isActive ? 'bi bi-volume-mute' : 'bi bi-volume-down'}></span>{isActive ? 'Mute video' : 'Unmute video'}</button>
            </div>
            <div className='logo-container' style={{ marginTop: Math.pow(offsetY, 2) * -0.0003 + 'px', transition: 'margin-top ease-out 300ms' }}>
                <div className='logo-wrapper' style={{ opacity: ((window.innerHeight / (offsetY + 0.001)) - 1.2) }}>
                    <svg id="logo" data-name="logo" className={isActive ? 'logo-svg deactivate-logo' : 'logo-svg'} xmlns="http://www.w3.org/2000/svg" width="800" height="800" viewBox="0 -100 800 1000">
                        <defs>
                        </defs>
                        <path d="M347.5,14.61L95.52,160.09c-33.74,19.48-54.52,55.48-54.52,94.43v290.95c0,38.96,20.78,74.96,54.52,94.44l251.97,145.48c33.74,19.48,75.31,19.48,109.04,0l251.97-145.48c33.74-19.48,54.52-55.48,54.52-94.44V254.52c0-38.96-20.78-74.96-54.52-94.43L456.54,14.61c-33.74-19.48-75.31-19.48-109.05,0Zm83.98,692.12l-25.81-74.36c-1.2-3.45-6.09-3.45-7.28,0l-25.81,74.36c-2.25,6.5-9.87,9.33-15.82,5.89l-200.76-115.91c-29.3-16.91-47.34-48.17-47.34-82v-231.82c0-6.88,6.26-12.05,13.01-10.76l77.3,14.82c3.59,.69,6.03-3.54,3.64-6.31l-51.49-59.53c-4.5-5.2-3.15-13.21,2.81-16.65l200.76-115.91c29.3-16.91,65.39-16.91,94.68,0l200.76,115.91c5.95,3.44,7.31,11.45,2.81,16.65l-51.49,59.53c-2.39,2.77,.05,7,3.64,6.31l77.3-14.82c6.75-1.29,13.01,3.88,13.01,10.76v231.82c0,33.83-18.05,65.09-47.34,82l-200.76,115.91c-5.95,3.44-13.57,.6-15.82-5.89Z" />
                        <path d="M308.4,350.15l59.8,69.37,30.18,86.47c1.2,3.45,6.08,3.45,7.28,0l30.18-86.47,59.8-69.37c2.38-2.77-.05-6.99-3.64-6.3l-89.98,17.1-89.98-17.1c-3.59-.68-6.02,3.54-3.64,6.3Z" />
                    </svg>

                    <svg id="lettering-1" data-name="lettering-1" className={isActive ? 'logo-svg deactivate-logo' : 'logo-svg'} xmlns="http://www.w3.org/2000/svg" width="721.56" height="145" viewBox="0 0 721.56 145">
                        <polygon points="98.03 79.37 25.9 79.37 25.9 2.26 0 2.26 0 141.73 25.9 141.73 25.9 101.48 98.03 101.48 98.03 141.73 123.93 141.73 123.93 2.26 98.03 2.26 98.03 79.37" />
                        <polygon points="213.89 70.71 173.05 2.77 145.35 2.77 200.15 93.42 200.15 142.23 226.05 142.23 226.05 93.02 280.64 2.77 255.14 2.77 213.89 70.71" />
                        <path d="M388.53,8.74c-8.97-3.98-19.49-5.98-31.58-5.98h-57.38V142.23h25.9v-40.25h31.48c12.08,0,22.61-1.99,31.58-5.98,8.97-3.98,15.84-9.73,20.62-17.23,4.78-7.5,7.17-16.3,7.17-26.4s-2.39-19.06-7.17-26.5c-4.78-7.44-11.66-13.15-20.62-17.13Zm-7.07,64.16c-5.85,4.78-14.41,7.17-25.7,7.17h-30.28V24.68h30.28c11.29,0,19.86,2.39,25.7,7.17,5.84,4.78,8.77,11.62,8.77,20.52s-2.92,15.74-8.77,20.52Z" />
                        <polygon points="472.11 82.06 539.45 82.06 539.45 60.75 472.11 60.75 472.11 24.48 548.02 24.48 548.02 2.77 446.21 2.77 446.21 142.23 550.81 142.23 550.81 120.52 472.11 120.52 472.11 82.06" />
                        <path d="M692.77,79.47c5.11-7.57,7.67-16.6,7.67-27.1s-2.39-19.06-7.17-26.5c-4.78-7.44-11.66-13.15-20.62-17.13-8.97-3.98-19.49-5.98-31.58-5.98h-57.38V142.23h25.9v-40.64h31.48c2.39,0,4.18-.07,5.38-.2l28.49,40.84h27.89l-32.08-45.83c9.56-3.72,16.9-9.36,22.02-16.94Zm-27.2-6.48c-5.85,4.85-14.41,7.27-25.7,7.27h-30.29V24.68h30.29c11.29,0,19.85,2.39,25.7,7.17,5.84,4.78,8.77,11.62,8.77,20.52s-2.92,15.77-8.77,20.62Z" />
                    </svg>
                    <svg id="lettering-2" data-name="lettering-2" className={isActive ? 'logo-svg deactivate-logo' : 'logo-svg'} xmlns="http://www.w3.org/2000/svg" width="878.44" height="145" viewBox="0 0 878.44 145">
                        <path d="M87.27,69.41c-6.64-2.59-15.14-5.08-25.5-7.47-7.57-1.86-13.55-3.52-17.93-4.98-4.38-1.46-8-3.45-10.86-5.98-2.86-2.52-4.28-5.78-4.28-9.76,0-5.71,2.49-10.29,7.47-13.75,4.98-3.45,12.58-5.18,22.81-5.18,6.24,0,12.68,.93,19.33,2.79,6.64,1.86,13.02,4.58,19.13,8.17l8.17-20.12c-5.98-3.98-13.08-7.04-21.32-9.17-8.24-2.12-16.61-3.19-25.1-3.19-12.22,0-22.55,1.86-30.98,5.58-8.44,3.72-14.74,8.74-18.93,15.04-4.18,6.31-6.28,13.32-6.28,21.02,0,9.17,2.36,16.44,7.07,21.82,4.71,5.38,10.36,9.33,16.94,11.85,6.57,2.52,15.17,5.05,25.8,7.57,7.57,1.86,13.55,3.52,17.93,4.98,4.38,1.46,8,3.49,10.86,6.08,2.86,2.59,4.28,5.88,4.28,9.86,0,5.45-2.56,9.83-7.67,13.15-5.11,3.32-12.85,4.98-23.21,4.98-8.37,0-16.71-1.43-25-4.28-8.3-2.85-15.31-6.54-21.02-11.06L0,127.49c5.84,5.05,13.78,9.1,23.81,12.15,10.03,3.05,20.42,4.58,31.18,4.58,12.22,0,22.58-1.86,31.08-5.58,8.5-3.72,14.84-8.7,19.03-14.94,4.18-6.24,6.28-13.22,6.28-20.92,0-9.03-2.36-16.2-7.07-21.52-4.72-5.31-10.4-9.26-17.03-11.85Z" />
                        <path d="M408.24,80.47c0,27.36-11.75,41.04-35.27,41.04s-35.47-13.68-35.47-41.04V2.77h-25.9V81.47c0,20.19,5.38,35.7,16.14,46.52,10.76,10.83,25.77,16.24,45.03,16.24s34.24-5.41,44.93-16.24c10.69-10.82,16.04-26.33,16.04-46.52V2.77h-25.5V80.47Z" />
                        <polygon points="514.04 2.77 451.28 142.23 477.97 142.23 526.59 28.67 557.93 101.59 511.16 101.59 502.39 121.91 566.67 121.91 575.4 142.23 602.5 142.23 539.54 2.77 514.04 2.77" />
                        <path d="M733.3,79.47c5.11-7.57,7.67-16.6,7.67-27.1s-2.39-19.06-7.17-26.5c-4.78-7.44-11.66-13.15-20.62-17.13-8.97-3.98-19.49-5.98-31.58-5.98h-57.38V142.23h25.9v-40.64h31.48c2.39,0,4.18-.07,5.38-.2l28.49,40.84h27.89l-32.08-45.83c9.56-3.72,16.9-9.36,22.02-16.94Zm-27.2-6.48c-5.85,4.85-14.41,7.27-25.7,7.27h-30.28V24.68h30.28c11.29,0,19.85,2.39,25.7,7.17,5.84,4.78,8.77,11.62,8.77,20.52s-2.92,15.77-8.77,20.62Z" />
                        <polygon points="799.74 120.52 799.74 82.06 867.09 82.06 867.09 60.75 799.74 60.75 799.74 24.48 875.65 24.48 875.65 2.77 773.84 2.77 773.84 142.23 878.44 142.23 878.44 120.52 799.74 120.52" />
                        <path d="M271.56,109.36c6.51-10.89,9.76-23.18,9.76-36.86s-3.26-25.97-9.76-36.86c-6.51-10.89-15.48-19.43-26.9-25.6C233.24,3.86,220.42,.77,206.21,.77s-27.03,3.09-38.45,9.26c-11.42,6.18-20.39,14.74-26.9,25.7-6.51,10.96-9.76,23.21-9.76,36.76s3.25,25.8,9.76,36.76c6.51,10.96,15.47,19.53,26.9,25.7,11.42,6.18,24.24,9.26,38.45,9.26,1.84,0,3.65-.05,5.45-.16l-.07,.16h69.73l-8.57-20.32h-12.78c4.47-4.26,8.33-9.11,11.58-14.54Zm-90.45,5.88c-7.44-4.18-13.28-10.03-17.53-17.53-4.25-7.51-6.38-15.91-6.38-25.2s2.12-17.7,6.38-25.2c4.25-7.5,10.09-13.35,17.53-17.53,7.44-4.18,15.81-6.28,25.1-6.28s17.66,2.09,25.1,6.28c7.44,4.18,13.28,10.03,17.53,17.53,4.25,7.51,6.38,15.91,6.38,25.2s-2.13,17.7-6.38,25.2c-4.25,7.5-10.1,13.35-17.53,17.53-7.44,4.18-15.81,6.28-25.1,6.28s-17.67-2.09-25.1-6.28Z" />
                    </svg>
                    <h1 className={isActive ? 'logo-subtitle deactivate-subtitle' : 'logo-subtitle'}><ReactMarkdown>{data.data.attributes.subtitle}</ReactMarkdown></h1>
                </div>
            </div>
        </section>
    )


}