import React from 'react';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

function Routing() {
    return (
        <>
            <Router>
                <App />
            </Router>

        </>
    )
}

export default Routing;