import React, { useEffect } from 'react'
import useFetch from '../hooks/useFetch'
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';
import './ServiceDetails.css';
import './LegalNotice.css';
import ReactMarkdown from 'react-markdown';
import HypersquareLogo from '../components/HypersquareLogo';
import { Helmet } from 'react-helmet';

export default function LegalNotice() {
    const { loading, error, data } = useFetch('https://hypersquare-backend.herokuapp.com/api/legal-notice')

    document.body.classList.add('scroll-blocker');
    useEffect(() => {
        return () => {
            document.body.classList.remove('scroll-blocker');
        }
    }, [])

    if (loading) return <Loader />
    if (error) return <p>There has been an Error.</p>

    return (
        <>
            <Helmet>
                <title>Hypersquare Design: {data.data.attributes.title}</title>
                <meta property="og:title" content={`Hypersquare Design: ${data.data.attributes.title}`} />
                <meta property="og:type" content="article" />
            </Helmet>
            <div className='service-detail-overlay' id='scroll-container'>
                <div className='service-head-wrapper'>

                    <div className='header-logo-wrapper'>
                        <Link to="/"><HypersquareLogo /></Link>
                        <Link to="/" className='text-link'><span className="bi bi-arrow-left-short back-icon"></span><span className='desktop-button'>Back to main page</span><span className='mobile-button'>Back</span></Link>
                    </div>
                </div>
                <div className='service-text-wrapper legal-notice-detail'>
                    <h1>{data.data.attributes.title}</h1>
                    <ReactMarkdown>{data.data.attributes.text}</ReactMarkdown>
                </div>
            </div>
        </>
    )
}

