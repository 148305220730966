import React from "react";
import './ProjectSection.css'
import useFetch from '../hooks/useFetch'
import ReactMarkdown from 'react-markdown'
import ProjectCard from "./ProjectCard";
import Loader from "./Loader";
import LogoSection from "./LogoSection";


export default function ProjectSection() {

    const { loading, error, data } = useFetch('https://hypersquare-backend.herokuapp.com/api/project-head?populate=media')
    if (loading) return <Loader />
    if (error) return <p>There has been an Error.</p>
    return (
        <section className='project-section' id='projects'>
            <div className="main-container">
            <div className='project-head'>
                <video autoPlay muted loop playsInline >
                    <source src={data.data.attributes.media.data.attributes.url} type={data.data.attributes.media.data.attributes.mime} />
                    <img src={data.data.attributes.media.data.attributes.previewUrl} />
                </video>
                <span className='text-wrapper'>
                    <span className='intro-subtitle'>{data.data.attributes.subtitle}</span>
                    <h1>{data.data.attributes.title}</h1>
                    <ReactMarkdown>{data.data.attributes.description}</ReactMarkdown>
                </span>
            </div>
            </div>
            <ProjectCard />
            <LogoSection />
        </section>
    )
}