import React from 'react'

export default function HypersquareLogo() {
  return (
      <svg id="hypersquare-logo" data-name="hypersquare-logo" xmlns="http://www.w3.org/2000/svg" width="1000" height="160" viewBox="0 0 1000 160">
        <g>
          <path className="hypersquare-logo" d="M61.07,3.21L10.86,32.19C4.14,36.08,0,43.25,0,51.01v57.98c0,7.76,4.14,14.94,10.86,18.82l50.21,28.99c6.72,3.88,15.01,3.88,21.73,0l50.21-28.99c6.72-3.88,10.86-11.05,10.86-18.82V51.01c0-7.76-4.14-14.94-10.86-18.82L82.8,3.21c-6.72-3.88-15.01-3.88-21.73,0Zm16.73,137.92l-5.14-14.82c-.24-.69-1.21-.69-1.45,0l-5.14,14.82c-.45,1.29-1.97,1.86-3.15,1.17l-40-23.1c-5.84-3.37-9.43-9.6-9.43-16.34V56.67c0-1.37,1.25-2.4,2.59-2.14l15.4,2.95c.72,.14,1.2-.71,.73-1.26l-10.26-11.86c-.9-1.04-.63-2.63,.56-3.32L62.5,17.94c5.84-3.37,13.03-3.37,18.87,0l40,23.1c1.19,.69,1.46,2.28,.56,3.32l-10.26,11.86c-.48,.55,.01,1.39,.73,1.26l15.4-2.95c1.35-.26,2.59,.77,2.59,2.14v46.19c0,6.74-3.6,12.97-9.43,16.34l-40,23.1c-1.19,.69-2.7,.12-3.15-1.17Z" />
          <path className="hypersquare-logo" d="M53.28,70.07l11.92,13.82,6.01,17.23c.24,.69,1.21,.69,1.45,0l6.01-17.23,11.92-13.82c.47-.55-.01-1.39-.73-1.26l-17.93,3.41-17.93-3.41c-.71-.14-1.2,.71-.73,1.26Z" />
        </g>
        <polygon className="hypersquare-logo" points="221.53 83.56 184.15 83.56 184.15 43.59 170.73 43.59 170.73 115.88 184.15 115.88 184.15 95.02 221.53 95.02 221.53 115.88 234.96 115.88 234.96 43.59 221.53 43.59 221.53 83.56" />
        <polygon className="hypersquare-logo" points="281.58 79.07 260.42 43.86 246.06 43.86 274.46 90.84 274.46 116.14 287.88 116.14 287.88 90.64 316.18 43.86 302.96 43.86 281.58 79.07" />
        <path className="hypersquare-logo" d="M372.1,46.95c-4.65-2.07-10.1-3.1-16.37-3.1h-29.74V116.14h13.42v-20.86h16.32c6.26,0,11.72-1.03,16.37-3.1,4.65-2.07,8.21-5.04,10.69-8.93,2.48-3.89,3.72-8.45,3.72-13.68s-1.24-9.88-3.72-13.73c-2.48-3.85-6.04-6.82-10.69-8.88Zm-3.67,33.25c-3.03,2.48-7.47,3.72-13.32,3.72h-15.7v-28.71h15.7c5.85,0,10.29,1.24,13.32,3.72,3.03,2.48,4.54,6.02,4.54,10.64s-1.52,8.16-4.54,10.64Z" />
        <polygon className="hypersquare-logo" points="415.42 84.96 450.32 84.96 450.32 73.91 415.42 73.91 415.42 55.11 454.76 55.11 454.76 43.86 401.99 43.86 401.99 116.14 456.21 116.14 456.21 104.89 415.42 104.89 415.42 84.96" />
        <path className="hypersquare-logo" d="M529.78,83.61c2.65-3.92,3.98-8.6,3.98-14.04s-1.24-9.88-3.72-13.73c-2.48-3.85-6.04-6.82-10.69-8.88-4.65-2.07-10.1-3.1-16.37-3.1h-29.74V116.14h13.42v-21.07h16.32c1.24,0,2.17-.03,2.79-.1l14.77,21.17h14.46l-16.63-23.75c4.96-1.93,8.76-4.85,11.41-8.78Zm-14.1-3.36c-3.03,2.51-7.47,3.77-13.32,3.77h-15.7v-28.81h15.7c5.85,0,10.29,1.24,13.32,3.72,3.03,2.48,4.54,6.02,4.54,10.64s-1.52,8.18-4.54,10.69Z" />
        <path className="hypersquare-logo" d="M589.94,78.4c-3.44-1.34-7.85-2.63-13.22-3.87-3.92-.96-7.02-1.82-9.29-2.58-2.27-.76-4.15-1.79-5.63-3.1-1.48-1.31-2.22-2.99-2.22-5.06,0-2.96,1.29-5.33,3.87-7.13,2.58-1.79,6.52-2.68,11.82-2.68,3.23,0,6.57,.48,10.02,1.45,3.44,.96,6.75,2.38,9.91,4.23l4.23-10.43c-3.1-2.07-6.78-3.65-11.05-4.75-4.27-1.1-8.61-1.65-13.01-1.65-6.33,0-11.69,.96-16.06,2.89-4.37,1.93-7.64,4.53-9.81,7.8-2.17,3.27-3.25,6.9-3.25,10.89,0,4.75,1.22,8.52,3.67,11.31,2.44,2.79,5.37,4.84,8.78,6.14,3.41,1.31,7.86,2.62,13.37,3.92,3.92,.96,7.02,1.83,9.29,2.58,2.27,.76,4.15,1.81,5.63,3.15,1.48,1.34,2.22,3.05,2.22,5.11,0,2.82-1.33,5.1-3.98,6.82-2.65,1.72-6.66,2.58-12.03,2.58-4.34,0-8.66-.74-12.96-2.22-4.3-1.48-7.94-3.39-10.89-5.73l-4.65,10.43c3.03,2.62,7.14,4.72,12.34,6.3,5.2,1.58,10.58,2.38,16.16,2.38,6.33,0,11.7-.96,16.11-2.89,4.41-1.93,7.69-4.51,9.86-7.74,2.17-3.24,3.25-6.85,3.25-10.84,0-4.68-1.22-8.4-3.67-11.15-2.44-2.75-5.39-4.8-8.83-6.14Z" />
        <path className="hypersquare-logo" d="M756.3,84.13c0,14.18-6.09,21.27-18.28,21.27s-18.38-7.09-18.38-21.27V43.86h-13.42v40.79c0,10.47,2.79,18.5,8.36,24.11,5.58,5.61,13.35,8.42,23.34,8.42s17.74-2.8,23.29-8.42c5.54-5.61,8.31-13.65,8.31-24.11V43.86h-13.22v40.27Z" />
        <polygon className="hypersquare-logo" points="811.13 43.86 778.6 116.14 792.44 116.14 817.63 57.28 833.88 95.08 809.64 95.08 805.09 105.61 838.41 105.61 842.93 116.14 856.98 116.14 824.35 43.86 811.13 43.86" />
        <path className="hypersquare-logo" d="M924.77,83.61c2.65-3.92,3.98-8.6,3.98-14.04s-1.24-9.88-3.72-13.73c-2.48-3.85-6.04-6.82-10.69-8.88-4.65-2.07-10.1-3.1-16.37-3.1h-29.74V116.14h13.42v-21.07h16.32c1.24,0,2.17-.03,2.79-.1l14.77,21.17h14.46l-16.63-23.75c4.96-1.93,8.76-4.85,11.41-8.78Zm-14.1-3.36c-3.03,2.51-7.47,3.77-13.32,3.77h-15.7v-28.81h15.7c5.85,0,10.29,1.24,13.32,3.72,3.03,2.48,4.54,6.02,4.54,10.64s-1.52,8.18-4.54,10.69Z" />
        <polygon className="hypersquare-logo" points="959.21 104.89 959.21 84.96 994.11 84.96 994.11 73.91 959.21 73.91 959.21 55.11 998.55 55.11 998.55 43.86 945.79 43.86 945.79 116.14 1000 116.14 1000 104.89 959.21 104.89" />
        <path className="hypersquare-logo" d="M685.46,99.1c3.37-5.65,5.06-12.01,5.06-19.1s-1.69-13.46-5.06-19.1c-3.37-5.64-8.02-10.07-13.94-13.27-5.92-3.2-12.56-4.8-19.93-4.8s-14.01,1.6-19.93,4.8c-5.92,3.2-10.57,7.64-13.94,13.32-3.37,5.68-5.06,12.03-5.06,19.05s1.69,13.37,5.06,19.05c3.37,5.68,8.02,10.12,13.94,13.32,5.92,3.2,12.56,4.8,19.93,4.8,.95,0,1.89-.03,2.82-.08l-.04,.08h36.14l-4.44-10.53h-6.62c2.32-2.21,4.32-4.72,6-7.54Zm-46.88,3.05c-3.86-2.17-6.88-5.2-9.09-9.09-2.2-3.89-3.3-8.25-3.3-13.06s1.1-9.17,3.3-13.06c2.2-3.89,5.23-6.92,9.09-9.09,3.85-2.17,8.19-3.25,13.01-3.25s9.16,1.08,13.01,3.25c3.85,2.17,6.88,5.2,9.09,9.09,2.2,3.89,3.3,8.25,3.3,13.06s-1.1,9.17-3.3,13.06c-2.2,3.89-5.23,6.92-9.09,9.09-3.86,2.17-8.19,3.25-13.01,3.25s-9.16-1.08-13.01-3.25Z" />
      </svg>
  )
}