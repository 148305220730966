import React from "react";
import './LogoSection.css'
import useFetch from '../hooks/useFetch'
import Loader from "./Loader";


export default function LogoSection() {
    const { loading, error, data } = useFetch('https://hypersquare-backend.herokuapp.com/api/partners?populate=logo')

    const openInNewTab = url => {
        // 👇️ setting target to _blank with window.open
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    if (loading) return <Loader />
    if (error) return <p>There has been an Error.</p>
    return (
        <div className='logo-section'>
            <h2>Some of our customers & partners</h2>
            <div className="logo-section-wrapper">
                {data.data.map((article) => (

                    <span onClick={() => openInNewTab(article.attributes.link)} className='partner-logo' key={article.id}>
                        <img src={article.attributes.logo.data.attributes.url}
                            className='partner-logo-img' alt={article.attributes.info} title={article.attributes.info}></img>
                    </span>

                ))
                }
            </div>
        </div>
    )
}