import React from "react";
import { useParams } from 'react-router-dom'
import useFetch from '../hooks/useFetch'
import Loader from './Loader';


export default function Redirect() {
    const { slug } = useParams()
    const { loading, error, data } = useFetch('https://hypersquare-backend.herokuapp.com/api/links/' + slug + '?populate=all')
    if (loading) return <p><Loader /></p>
    if (error) return <p>There has been an Error.</p>

            window.location.href = data.data.attributes.link;
    return (
        <>
            <Loader />
        </>
    );

}
