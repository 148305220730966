import React from "react";
import './FooterSection.css'
import useFetch from '../hooks/useFetch'
import ReactMarkdown from 'react-markdown'
import Loader from "./Loader";
import { Link, useLocation } from "react-router-dom";

export default function FooterSection() {
    const { loading, error, data } = useFetch('https://hypersquare-backend.herokuapp.com/api/footer')

    const location = useLocation()

    const openInNewTab = url => {
        // 👇️ setting target to _blank with window.open
        window.open(url, '_blank', 'noopener,noreferrer');
    };


    function TawkToggle() {
        return {
            __html: `<a class="std-button" href="javascript:void(Tawk_API.toggle())"><span class="bi bi-chat-left-dots left-icon"></span> Live chat now <span class="bi bi-arrow-right-short"></span></a>`
        };
    }


    if (loading) return <Loader />
    if (error) return <p>There has been an Error.</p>
    return (

        <div className='contact-area'>
            <div className="contact-text-wrapper">
                <h1>{data.data.attributes.title}</h1>
                <ReactMarkdown>{data.data.attributes.text}</ReactMarkdown>
                <button dangerouslySetInnerHTML={TawkToggle()} />
                <button className="std-button" onClick={() => openInNewTab('mailto:hello@hypersquare.design?subject=Inquiry sent via hypersquare.design')}><span className="bi bi-envelope left-icon"></span> hello@hypersquare.design <span className="bi bi-arrow-right-short"></span></button>
            </div>
            <div className="footer-links">
                <div className="social-wrapper">
                    <span onClick={() => openInNewTab('https://www.linkedin.com/company/75593378')}><span className="bi bi-linkedin social-icons"></span></span>
                    <span onClick={() => openInNewTab('https://www.instagram.com/hypersquare.design/')}><span className="bi bi-instagram social-icons"></span></span>
                    <span onClick={() => openInNewTab('https://www.facebook.com/hypersquare.design')}><span className="bi bi-facebook social-icons"></span></span>
                </div>
                <div className="footer-nav-wrapper">
                    <Link to={{ pathname: `/legal-notice` }} state={{ background: location }}>
                        <span className="footer-nav footer-nav-active">Legal Notice</span>
                    </Link>
                    <span className="footer-nav divider">|</span>
                    <span className="footer-nav">{data.data.attributes.copyright}</span>

                </div>
            </div>

        </div>


    )

}