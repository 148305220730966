import React from 'react'
import './ServiceCard.css';
import useFetch from '../hooks/useFetch'
import { Link, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import Loader from './Loader';

export const ServiceCard = () => {
    const { loading, error, data } = useFetch('https://hypersquare-backend.herokuapp.com/api/services?populate=media')

    const location = useLocation()


    if (loading) return <Loader />
    if (error) return <p>There has been an Error.</p>
    return (
        <div className='service-wrapper'>
            {data.data && data.data
                .sort((a, b) => a.id > b.id ? 1 : -1)
                .map((article, i) => (
                    <Link key={`service-link${i}`} to={{ pathname: `/services/${article.attributes.slug}` }} className='service-card-link' state={{ background: location }}>
                        <div key={`service-card-wrapper${i}`} className='service-card-wrapper'>
                            <div key={`service-card${i}`} className='service-card'>
                                <div key={`service-card-head${i}`} className='service-card-head'>
                                    <h2 key={`service-card-title${i}`}>{article.attributes.title}</h2>
                                </div>
                                <img key={`service-card-image${i}`} src={article.attributes.media.data.attributes.url} className='service-card-image'></img>

                            </div>

                            <div key={`service-card-text${i}`} className='service-card-text'>
                                <ReactMarkdown>{article.attributes.description}</ReactMarkdown>
                                <div key={`service-text-link${i}`} className='text-link'>Find out more<span className="bi bi-arrow-right-short"></span></div>
                            </div>
                        
                        </div>
                    </Link>
                ))
            }
        </div >
    )
}