import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { Homepage } from './pages/Homepage';
import { ServiceDetails } from './pages/ServiceDetails';
import Redirect from './components/Redirect';

import React, { useRef } from "react";

import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


import LegalNotice from './pages/LegalNotice';
import { ProjectDetails } from './pages/ProjectDetails';


function App() {
  const tawkMessengerRef = useRef();

  const location = useLocation();
  const background = location.state && location.state.background;  

  return (
    <div className="App">
      <Routes location={background || location}>
        <Route path="/" element={<Homepage />} >
          <Route path="/services/:slug" element={<ServiceDetails />} />
          <Route path="/projects/:slug" element={<ProjectDetails />} />
          <Route path="/legal-notice" element={<LegalNotice />} />
        </Route>

        <Route path="/link/:slug" element={<Redirect />} />
        <Route path="*" element={<Homepage />} />
      </Routes>

      {background && (
        <Routes>
          <Route path="services/:slug" element={<ServiceDetails />} />
          <Route path="/projects/:slug" element={<ProjectDetails />} />
          <Route path="/legal-notice" element={<LegalNotice />} />
        </Routes>)}

      <TawkMessengerReact
        propertyId="62c5d9c97b967b1179985c2c"
        widgetId="1g7adlf7l"
        ref={tawkMessengerRef} />
    </div>
  );
}

export default App;